@import '../../scss/variables';

.fancyBox {	
	line-height: 1.8em;
	padding-top: 0.2em;
	padding-bottom: 2em;
	.content {
		margin: auto;
		animation: initFancyBox .5s;
		p, h1, h2, h3, h4, blockquote, ul {
			padding: 0 15px;
			max-width: 720px;
			margin-left: auto;
			margin-right: auto;
		}
		blockquote {
			background-color: #f5f5f5;
			padding: 15px;
			line-height: 2em;
			p {
				font-style: italic;
			}
		}

		blockquote + figcaption {
			background-color: $pervanovoLightGrey;
			margin-top: -15px;
			padding: 15px;
			max-width: 720px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	h1, h2, h3, h4 {
		color: inherit !important;
	}

	h1:after {
		content: url('divider.svg');
		display: block;
	}
	&.white  {
		h1:after {
			content: url('divider_white.svg');
			display: block;
		}
		a:hover {
			color: $pervanovoWhite;
		}
	}
	
}

@keyframes initFancyBox {
	from {
		opacity: 0;
	}
}