@import '../../scss/variables';

.contactBox {
	flex: 0 1 250px;
	line-height: 1.5em;
	margin: 15px;
	padding: 15px;
	align-self: flex-start;
	.contactItem {
		h2 {
			font-size: 1em;
			line-height: 1em;
		}

		h1 {
			font-size: 1.2em;
		}
	}
	.contactList {
		list-style-type: none;
		margin-left: 0;
		padding: 0;
		li {
			text-align: center;
		}
	}
}


#map {
	height: 700px;
	border-radius: $pervanovoRadius;
	width: 100%;
	overflow: hidden;
	margin-bottom: 30px;
	box-shadow: $smallShadow;
	@media #{$mediaLarge} {
		border-radius: unset;
	}
}