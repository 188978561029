//Colours
$pervanovoRed: #CF0000;
$pervanovoDarkRed: #230007;
$pervanovoBlack: #100B00;
$pervanovoWhite: #FDFFFC;
$pervanovoGrey: #403434;
$pervanovoLightGrey: #dfe2e1;

$pervanovoRadius: 15px;
$smallRadius: 4px;

//Sizes
$largeWidth: 1200px;
$smallWidth: 560px;
$contentWidth: 720px;

//media
$mediaSmall: screen and (max-width: $smallWidth);
$mediaLarge: screen and (max-width: $largeWidth);
$mediaContent: screen and (max-width: $contentWidth);

//Effects
$smallShadow: 0 1px 2px RGBA(0,0,0,.25);