@import '../../scss/variables';
.newsBox {
  margin-top: 30px;
  .content {
    max-width: $largeWidth;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 5px 10px RGBA(0, 0, 0, 0.15);
    p {
      text-align: left;
    }
  }

  .publishDate {
    color: $pervanovoGrey;
  }
}