@import '../../scss/variables';

.banner {
	&.sizeToFit {
		max-width: 100%;
	}
	width: 100vw;
	max-width: $largeWidth;
	height: 500px;
	background-position: center center;
	background-size: cover;
	margin: 15px auto;
	background-repeat: no-repeat;
	border-radius: $smallRadius;
	display: flex;
	justify-content: flex-start;
	overflow: hidden;
	box-shadow: $smallShadow;

	.caption {
		flex: 1 1 content;
		align-self: flex-end;
		background: $pervanovoBlack;
		font-weight: 600;
		font-size: 1.2em;
		color: $pervanovoWhite;
		padding: 15px 30px;
		text-align: left;
		border-radius: $smallRadius;
		box-shadow: $smallShadow;
		opacity: 0.95;
		margin-bottom: 15px;
		margin-left: 15px;

		h1 {
			font-size: 1.4em;
			margin: 0;
		}

		h1, h2, h3, h4 {
			color: $pervanovoWhite;
		}
		@media #{$mediaContent} {
			flex: 1 1 auto;
			text-align: center;
			padding: 10px 15px;
			margin: 5px;
		}

		@media #{$mediaSmall} {
			font-size: 0.8em;
		}
	}
}
@media #{$mediaLarge} {
	.banner {
		border-radius: unset;
	}
}