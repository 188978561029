@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,&amp;subset=latin-ext');
@import '../../scss/variables';
.App {
	text-align: center;
	font-family: "Open Sans", sans-serif;
	color: $pervanovoBlack;
	background-color: $pervanovoWhite;

	p {
		line-height: 1.8em;
	}

	h1 {
		font-weight: 600;
		font-size: 2.5em;
		line-height: 1em;
	}
	h1 {
		font-weight: 600;
	}
	h1, h2, h3, h4 {
		color: $pervanovoDarkRed;
	}
	a {
		font-weight: 600;
		color: $pervanovoRed;
		text-decoration: none;
		&:hover {
			color: $pervanovoDarkRed;
		}
	}

	li {
		text-align: left;
	}
}