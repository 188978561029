@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,&amp;subset=latin-ext);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#topHeader{display:flex;max-width:1200px;width:100%;margin:auto;justify-content:space-between}#topHeader .logo{flex:1 1 content;max-height:115px;max-width:100%;padding:15px;box-sizing:border-box}#topHeader .navigation{flex:1 1 content;display:flex;justify-content:center;flex-wrap:wrap}@media screen and (max-width: 600px){#topHeader .navigation{justify-content:space-around}}#topHeader .navigation .navItem{flex:1 0 content;padding:40px 15px}@media screen and (max-width: 600px){#topHeader .navigation .navItem{font-size:0.9em}}@media screen and (max-width: 350px){#topHeader .navigation .navItem{font-size:0.7em}}#topHeader .navigation .navItem a{text-transform:uppercase;white-space:nowrap;color:#343434}#topHeader .navigation .navItem a:hover{color:#CE0000}#topHeader .navigation .navItem a.current{display:inline-block;font-weight:bold;color:#230007;-webkit-transform:scale(1.1);transform:scale(1.1);-webkit-animation:initSelectedNav .15s;animation:initSelectedNav .15s}@media screen and (max-width: 600px){#topHeader{flex-direction:column}}@-webkit-keyframes initSelectedNav{from{-webkit-transform:scale(1);transform:scale(1)}}@keyframes initSelectedNav{from{-webkit-transform:scale(1);transform:scale(1)}}

.banner{width:100vw;max-width:1200px;height:500px;background-position:center center;background-size:cover;margin:15px auto;background-repeat:no-repeat;border-radius:4px;display:flex;justify-content:flex-start;overflow:hidden;box-shadow:0 1px 2px RGBA(0, 0, 0, 0.25)}.banner.sizeToFit{max-width:100%}.banner .caption{flex:1 1 content;align-self:flex-end;background:#100B00;font-weight:600;font-size:1.2em;color:#FDFFFC;padding:15px 30px;text-align:left;border-radius:4px;box-shadow:0 1px 2px RGBA(0, 0, 0, 0.25);opacity:0.95;margin-bottom:15px;margin-left:15px}.banner .caption h1{font-size:1.4em;margin:0}.banner .caption h1,.banner .caption h2,.banner .caption h3,.banner .caption h4{color:#FDFFFC}@media (max-width: 720px){.banner .caption{flex:1 1 auto;text-align:center;padding:10px 15px;margin:5px}}@media (max-width: 560px){.banner .caption{font-size:0.8em}}@media (max-width: 1200px){.banner{border-radius:unset}}

.fancyBox{line-height:1.8em;padding-top:0.2em;padding-bottom:2em}.fancyBox .content{margin:auto;-webkit-animation:initFancyBox .5s;animation:initFancyBox .5s}.fancyBox .content p,.fancyBox .content h1,.fancyBox .content h2,.fancyBox .content h3,.fancyBox .content h4,.fancyBox .content blockquote,.fancyBox .content ul{padding:0 15px;max-width:720px;margin-left:auto;margin-right:auto}.fancyBox .content blockquote{background-color:#f5f5f5;padding:15px;line-height:2em}.fancyBox .content blockquote p{font-style:italic}.fancyBox .content blockquote+figcaption{background-color:#dfe2e1;margin-top:-15px;padding:15px;max-width:720px;margin-left:auto;margin-right:auto}.fancyBox h1,.fancyBox h2,.fancyBox h3,.fancyBox h4{color:inherit !important}.fancyBox h1:after{content:url(/static/media/divider.df5ffd96.svg);display:block}.fancyBox.white h1:after{content:url(/static/media/divider_white.66ea60db.svg);display:block}.fancyBox.white a:hover{color:#FDFFFC}@-webkit-keyframes initFancyBox{from{opacity:0}}@keyframes initFancyBox{from{opacity:0}}

.triviaBox{margin:30px}.triviaBox .content{font-size:1.6em;font-weight:600;text-align:center}.triviaBox .icon{font-size:110px;color:#CF0000}

.contentBox{display:flex;max-width:1200px;width:100%;margin:auto;justify-content:space-evenly;flex-wrap:wrap}

.pageHeader{background:#230007;color:white;font-weight:bold;font-size:1.5em;padding:15px;width:100%;box-sizing:border-box}.pageHeader .content{max-width:1200px;margin:auto;text-align:left}@media screen and (max-width: 600px){.pageHeader{font-size:1.5em}}

.pageBusinessUnits .unitLogo{max-height:72px;max-width:100%;padding:0 15px;box-sizing:border-box}

.ownershipChart{max-width:100%;padding:15px;box-sizing:border-box}

.contactBox{flex:0 1 250px;line-height:1.5em;margin:15px;padding:15px;align-self:flex-start}.contactBox .contactItem h2{font-size:1em;line-height:1em}.contactBox .contactItem h1{font-size:1.2em}.contactBox .contactList{list-style-type:none;margin-left:0;padding:0}.contactBox .contactList li{text-align:center}#map{height:700px;border-radius:15px;width:100%;overflow:hidden;margin-bottom:30px;box-shadow:0 1px 2px RGBA(0, 0, 0, 0.25)}@media (max-width: 1200px){#map{border-radius:unset}}

.newsBox{margin-top:30px}.newsBox .content{max-width:1200px;padding:30px;border-radius:10px;box-shadow:0 5px 10px RGBA(0, 0, 0, 0.15)}.newsBox .content p{text-align:left}.newsBox .publishDate{color:#403434}

.App{text-align:center;font-family:"Open Sans", sans-serif;color:#100B00;background-color:#FDFFFC}.App p{line-height:1.8em}.App h1{font-weight:600;font-size:2.5em;line-height:1em}.App h1{font-weight:600}.App h1,.App h2,.App h3,.App h4{color:#230007}.App a{font-weight:600;color:#CF0000;text-decoration:none}.App a:hover{color:#230007}.App li{text-align:left}

