@import '../../scss/variables';

.pageHeader {
	background: $pervanovoDarkRed;
	color: white;
	font-weight: bold;
	font-size: 1.5em;
	padding: 15px;
	width: 100%;
	box-sizing: border-box;
	.content {
		max-width: 1200px;
		margin: auto;
		text-align: left;
	}

	@media screen and (max-width: 600px) {
		font-size: 1.5em;
	}
}