.triviaBox {
	margin: 30px;
	.content {
		font-size: 1.6em;
		font-weight: 600;
		text-align: center;
	}
	.icon {
		font-size: 110px;
		color: #CF0000;
	}
}