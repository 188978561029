@import '../../scss/variables';

#topHeader {
	display: flex;
	max-width: 1200px;
	width: 100%;
	margin: auto;
	justify-content: space-between;
	.logo {
		flex: 1 1 content;
		max-height: 115px;
		max-width: 100%;
		padding: 15px;
		box-sizing: border-box;
	}
	.navigation {
		flex: 1 1 content;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		@media screen and (max-width: 600px) {
			justify-content: space-around;
		}
		.navItem {
			flex: 1 0 content;
			padding: 40px 15px;
			@media screen and (max-width: 600px) {
				font-size: 0.9em;
			}
			@media screen and (max-width: 350px) {
				font-size: 0.7em;
			}
			a {
				text-transform: uppercase;
				white-space: nowrap;
				color: #343434;
				&:hover {
					color: #CE0000;
				}
				&.current {
					display: inline-block;
					font-weight: bold;
					color: $pervanovoDarkRed;
					transform: scale(1.1);
					animation: initSelectedNav .15s;
				}
			}


		}
	}
	@media screen and (max-width: 600px) {
		flex-direction: column;
	}
}

@keyframes initSelectedNav {
	from {
		transform: scale(1);
	}
}